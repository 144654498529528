import './jquery.unveil';
import './styles.scss';

$(() => {
    let $img = $('img[data-src]');

    $img.attr('src', '/assets/img/layout/pixel-unveil.png');

    $img.unveil(0, function () {
        $(this).load(function () {
            this.style.opacity = 1;
        });
    });

    $(window).trigger('lookup');

    setTimeout(() => {
        $(window).trigger('lookup');
    }, 250);
});
