import $ from 'jquery';
import './styles.css';

$(document).on('click tap', '[data-href]', function (e) {
    e.preventDefault();

    let $elem = $(this);
    let href = $elem.data('href');

    if (0 === href.length) {
        let button = $elem.find('a:first');
        if (button.length && button.attr('href').length) {
            href = button.attr('href');
        }
    }

    if (href.length) {
        window.open(href, '_self');
    }
});
