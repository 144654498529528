import { csrf_token } from './js/core';

// Styles
import './sass/styles.scss';

// Scripts
import './js/modernizr';
import './js/animations';
import './js/layout';
import './js/unveil'
import './js/menu';
import './js/scroll-helpers';

// Global polyfill Promise
import 'es6-promise/auto';

// Whooohoo, lets go!
let ElementRoutes = {
    'img[srcset]'() {
        require('picturefill');
    },
    '[data-fileupload]': () => {
        require.ensure([], () => {
            require('./js/fileupload')
        })
    },
    '[data-href]'() {
        require('./js/data-href');
    },
    '[data-photoswipe]' () {
        require.ensure([], () => {
            require('./js/photoswipe')
        })
    },
    '[id="popup"]' () {
        require.ensure([], () => {
            require('./js/popup');
        });
    },
    'form.form-generic'() {
        require('./js/forms/generic');
    },
};

$.each(ElementRoutes, (key, callback) => {
    if ($(key).length) {
        callback();
        console.info('✓ ' + key);
    }
});

// CSRF Header to ajax request
$.ajaxSetup({
    headers: { 'X-CSRF-TOKEN': csrf_token() }
});

// Trigger click helper
$(document).on('click touchstart', '[data-trigger-click]', function (e) {
    try {
        e.preventDefault();
        $(this.getAttribute('data-trigger-click')).trigger('click');
    } catch (e) {
        //
    }
});

// Test for iPad or iPhone
if (navigator.userAgent.match(/(iPad|iPhone)/g)) {
    $('html').addClass('-is-ios');
}
