let $html = $('html');
let $window = $(window);

$(function () {

    let $hamburger = $('.hamburger');

    function toggleMenu(show) {
        $hamburger.toggleClass('is-active', show);
        $html.toggleClass('-has-fixed-nav-opened', show);
    }

    $window.on('scroll resize load', function () {
        toggleMenu(false);
    });

    $hamburger.on('click', function (event) {
        event.preventDefault();
        toggleMenu(!$hamburger.hasClass('is-active'));
    });

    $('.page-overlay').on('click', function (event) {
        event.preventDefault();
        $hamburger.click();
    });

});
