define([
    'jquery',
    './in-viewport',
    './styles/animate-fade-in',
    './styles/animate-slide-in',
    './styles/animate-zoom-in'
], function ($) {
    let animateables = $('.animate-fade-in, .animate-slide-in, .animate-zoom-in');

    animateables.each(function (i, element) {
        let el = $(element);
        if (el.visible(true)) {
            el.addClass('already-visible');
        }
    });

    $(window).on('scroll touchend load', function () {
        animateables.each(function (i, element) {
            let el = $(element);
            if (el.visible(true)) {
                el.addClass('come-in');
            }
        });
    });
});
