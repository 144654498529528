const core = {
    csrf_token() {
        return document.querySelector('meta[name="csrf-token"]')['content'];
    },
    debug() {
        return __DEBUG__;
    },
};

export let csrf_token = core.csrf_token;
export let debug = core.debug;

export default core;
