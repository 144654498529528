/*global ga*/
const analytics = {
    check() {
        if ('function' !== typeof ga) {
            console.warn('Google Analytics has not been installed!');
        }
    },

    sendEvent(category, action, label) {
        try {
            ga('send', 'event', category, action, label || null);
        } catch (error) {
            console.warn('ga()', arguments);
        }
    },

    sendPageview(page) {
        try {
            ga('send', 'pageview', page);
        } catch (error) {
            console.warn('ga()', arguments);
        }
    },
};

export let check = analytics.check;
export let sendEvent = analytics.sendEvent;
export let sendPageview = analytics.sendPageview;

export default analytics;
