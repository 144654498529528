import $ from 'jquery';

$(function () {
    let segments = window.location.pathname.split('/');
    let uri = segments[1];

    // if (3 <= segments.length) {
    //     uri = segments[1] + '/' + segments[2];
    // }

    // let href = '/' + (0 < uri.length ? uri.substring(0, uri.length - 1) : '');
    // href = window.location.origin + href;
    let href = '/' + uri;

    let linkElem = $([
        href === '/'
            ? '[id="menu"] a[href="/"]'
            : '[id="menu"] a[href^="' + href + '"]:first',
    ].join(','));

    if (linkElem.length) {
        linkElem.closest('li').addClass('current');
    }

});
