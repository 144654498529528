import './current';
import './mobile';

let $html = $('html');
let $window = $(window);

$(function () {

    let $headerNav = $('.header-nav');
    let offset = $headerNav.offset().top;

    $window.on('scroll resize load', function () {
        $html.toggleClass('-has-fixed-nav', $window.scrollTop() > offset);
    });

});
