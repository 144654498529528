import $ from 'jquery';

let $window = $(window);
let $document = $(document);
let $html = $('html');

// Scroll
$(function () {
    $window.on('scroll resize load', function () {
        let scroll = $window.scrollTop();
        let position = parseInt($window.scrollTop() + $window.height());
        let minPosition = parseInt($document.height() - 300);

        $html.toggleClass('-page-is-scrolled-down', scroll > 0);
        $html.toggleClass('-should-show-scroll-up', scroll > 200 && position > minPosition);
    });

    $('.block.-layed-over').each((i, elem) => {
        let $el = $(elem);
        let $prev = $el.prev();
        let $next = $el.next();

        if ($prev.is('.block')) {
            $prev.addClass('-before-layed-over');
        }

        if ($next.is('.block')) {
            $next.addClass('-after-layed-over');
        }
    });
});

// Loading class
$document.ajaxStart(() => {
    $html.addClass('-has-xhr-activity');
}).ajaxComplete(() => {
    $html.removeClass('-has-xhr-activity');
});
